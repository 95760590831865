import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import RecipesList from "../recipesList";

const AllRecipes = () => {
  const data = useStaticQuery(query);
  const recipes = data.allNotion.nodes;
  return (
    <div className="section">
      <div className="column is-10 is-offset-1">
        <h2 className="title is-1">Toutes les recettes</h2>
      </div>
      <div className="columns is-centered">
        <div className="column is-10 ">
          <RecipesList recettes={recipes} />
        </div>
      </div>
    </div>
  );
};

const query = graphql`
  {
    allNotion {
      nodes {
        id
        childMarkdownRemark {
          frontmatter {
            ajouteLe: Ajout_e_le(fromNow: true, locale: "fr")
            tags: Cat_gories {
              id
              name
            }
            title
          }
        }
        raw {
          imageFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                placeholder: TRACED_SVG
                layout: CONSTRAINED
                width: 1024
              )
            }
          }
        }
      }
    }
  }
`;
export default AllRecipes;
